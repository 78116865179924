import { Routes, Route } from 'react-router-dom';
import { ROUTER } from 'shared/constant/common';
import { PageProps } from 'shared/interface/common';
import React from 'react';

const HomePcLazy = React.lazy(() => import('../../pages/homepage/Home/HomepagePc'));
const HomePCBusinessLazy = React.lazy(
  () => import('../../pages/alan-business/Home/HomePCBusiness')
);
const AlanTechPcLazy = React.lazy(() => import('../../pages/alan-tech/AlanTechPC'));
const HomePCBeautyLazy = React.lazy(() => import('../../pages/alan-beauty/HomePC'));
const HomePCMediaLazy = React.lazy(() => import('../../pages/alan-media/Home/HomePCMedia'));
const ECommercePCLazy = React.lazy(() => import('../../pages/alan-ecommerce/AlanEcommercePC'));

const LayoutPC: React.FC<PageProps> = () => {
  return (
    <div className="pc__container-wrapper">
      <Routes>
        <Route path={`${ROUTER.INDEX}`} element={<HomePcLazy />} />
        <Route path={`${ROUTER.ALAN_BUSINESS}`} element={<HomePCBusinessLazy />} />
        <Route path={`${ROUTER.ALAN_TECH}`} element={<AlanTechPcLazy />} />
        <Route path={`${ROUTER.ALAN_BEAUTY}`} element={<HomePCBeautyLazy />} />
        <Route path={`${ROUTER.ALAN_MEDIA}`} element={<HomePCMediaLazy />} />
        <Route path={`${ROUTER.ALAN_ECOMMERCE}`} element={<ECommercePCLazy />} />
      </Routes>
    </div>
  );
};

export default LayoutPC;
