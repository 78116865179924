import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import DefaultLayout from 'components/layouts/DefaultLayout';
import { HistoryRouter } from 'components/HistoryRouter';
import { ROUTER } from 'shared/constant/common';
import { Route, Routes } from 'react-router-dom';
import { history } from 'shared/utils/history';
import 'assets/styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Routes>
        <Route path={ROUTER.ALL} element={<DefaultLayout />} />
      </Routes>
    </HistoryRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
