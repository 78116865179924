import { useState, useEffect } from 'react';
import { SIZE_DEVICE } from '../constant/common';

interface size {
  width: number;
}

function useWindowSize() {
  const [widthSize, setWidthSize] = useState<size>({
    width: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWidthSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return widthSize;
}

function useDevice() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const widthSize = useWindowSize();

  useEffect(() => {
    const checkIsMobile = widthSize.width <= SIZE_DEVICE.SIZE_MOBILE;
    setIsMobile(checkIsMobile);
  }, [widthSize.width]);

  return isMobile;
}

export { useWindowSize, useDevice };
