import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { ROUTER } from 'shared/constant/common';
import { PageProps } from 'shared/interface/common';

const HomeMobileLazy = React.lazy(() => import('../../pages/homepage/Home/HomeMobile'));
const HomeMobileBusinessLazy = React.lazy(
  () => import('../../pages/alan-business/Home/HomeMobileBusiness')
);
const HomeMobileMediaLazy = React.lazy(() => import('../../pages/alan-media/Home/HomeMobileMedia'));
const HomeMobileBeautyLazy = React.lazy(() => import('../../pages/alan-beauty/HomeMobileBeauty'));
const AlanTechMobileLazy = React.lazy(() => import('../../pages/alan-tech/AlanTechMobile'));
const EcommerceMobileLazy = React.lazy(
  () => import('../../pages/alan-ecommerce/AlanEcommerceMobile')
);

const LayoutMobile: React.FC<PageProps> = () => {
  return (
    <div className="mb__container-wrapper">
      <Routes>
        <Route path={`${ROUTER.INDEX}`} element={<HomeMobileLazy />} />
        <Route path={`${ROUTER.ALAN_BUSINESS}`} element={<HomeMobileBusinessLazy />} />
        <Route path={`${ROUTER.ALAN_MEDIA}`} element={<HomeMobileMediaLazy />} />
        <Route path={`${ROUTER.ALAN_BEAUTY}`} element={<HomeMobileBeautyLazy />} />
        <Route path={`${ROUTER.ALAN_TECH}`} element={<AlanTechMobileLazy />} />
        <Route path={`${ROUTER.ALAN_ECOMMERCE}`} element={<EcommerceMobileLazy />} />
      </Routes>
    </div>
  );
};

export default LayoutMobile;
