import { useDevice } from 'shared/HOOK/useSize';
import LayoutMobile from './LayoutMobile';
import LayoutPC from './LayoutPC';
import { Suspense, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PageProps } from 'shared/interface/common';
import { ToastContainer } from 'react-toastify';

const DefaultLayout: React.FC<PageProps> = () => {
  const isMobile = useDevice();
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Suspense>
        <ToastContainer />
        {isMobile ? <LayoutMobile /> : <LayoutPC />}
      </Suspense>
    </>
  );
};

export default DefaultLayout;
