const ROUTER = {
  ALL: '/*',
  INDEX: '/',
  ALAN_TECH: '/tech',
  ALAN_BUSINESS: '/business',
  ALAN_BEAUTY: '/beauty',
  ALAN_MEDIA: '/media',
  ALAN_ECOMMERCE: '/ecommerce',
};

const SIZE_DEVICE = {
  SIZE_MOBILE: 768,
};

const REGEX = {
  EMAIL:
    /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const HEADER = [
  {
    title: 'TRANG CHỦ',
    path: '/',
  },
  {
    title: 'DỊCH VỤ',
    path: '#',
    children: [
      {
        title: 'Alan Tech',
        path: '/tech',
      },
      {
        title: 'Alan  Beauty',
        path: '/beauty',
      },
      {
        title: 'Alan Media',
        path: '/media',
      },
      {
        title: 'Alan E-Commerce',
        path: '/ecommerce',
      },
      {
        title: 'Alan Business',
        path: '/business',
      },
    ],
  },
  {
    title: 'TIN TỨC',
    path: '#',
  },
  {
    title: 'TUYỂN DỤNG',
    path: '#',
  },
];

export { SIZE_DEVICE, ROUTER, REGEX, HEADER };
